export const videos = [
  {
    place: "Venice",
    id: "3Zz7M2vLHJ8",
    lat: "45.4408",
    lng: "12.3155",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Marseille",
    id: "JiAVdr9cOH4",
    lat: "43.2965",
    lng: "5.3698",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Bratislava",
    id: "HkY7QTIP1PU",
    lat: "48.1486",
    lng: "17.1077",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Faro",
    id: "2J9C16iyW2w",
    lat: "37.0194",
    lng: "-7.9304",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Saint-Tropez",
    id: "ZrNBVcb3xTU",
    lat: "43.2677",
    lng: "6.6407",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Buenos Aires",
    id: "0pXLBg1U-cQ",
    lat: "-34.6037",
    lng: "-58.3816",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Gorges du Veron",
    id: "mJrGKL3oSFo",
    lat: "43.7614",
    lng: "6.3788",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Sydney",
    id: "GqnCZNGkdfc",
    lat: "-33.8688",
    lng: "151.2093",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Istanbul",
    id: "wI9MS9M8yCU",
    lat: "41.0082",
    lng: "28.9784",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Budapest",
    id: "78Cb6rbgTG8",
    lat: "47.4979",
    lng: "19.0402",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Berlin",
    id: "A3wriE0MPos",
    lat: "52.5200",
    lng: "13.4050",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Antwerp",
    id: "A3xryVQUTs8",
    lat: "51.2213",
    lng: "4.4051",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Paris",
    id: "wtpaoi3SxgA",
    lat: "48.856613",
    lng: "2.352222",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Florence",
    id: "ajBikS8f21Y",
    lat: "43.769562",
    lng: "11.255814",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Morvan Park, France",
    id: "g54o4tfMBGc",
    lat: "47.1461",
    lng: "3.9560",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "The Hoge Veluwe National Park",
    id: "UR9cYRoSZp8",
    lat: "52.0787",
    lng: "5.8325",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Kinderdijk",
    id: "x1MSmW75P10",
    lat: "51.8837",
    lng: "4.6331",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "The Zaanse Schans",
    id: "Qo_L9QIfHUs",
    lat: "52.4729",
    lng: "4.8219",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Amsterdam",
    id: "1Jo4U-a7YkQ",
    lat: "52.3676",
    lng: "4.9041",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "La Palma",
    id: "AYRkaRteh4Y",
    lat: "28.7134",
    lng: "-17.9058",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },
  {
    place: "Arctic Ocean",
    id: "GWALz8GMxo8",
    lat: "73.321222",
    lng: "-153.045583",
    channelUrl: "https://www.youtube.com/@hughhou",
    channelName: "@hughhou",
  },

  {
    place: "Boston",
    id: "ppg9V8JMo1E",
    lat: "42.3601",
    lng: "-71.0589",
    channelUrl: "https://www.youtube.com/@hughhou",
    channelName: "@hughhou",
  },
  {
    place: "Plymouth Rock",
    id: "EOVuc1x885E",
    lat: "41.9581",
    lng: "-70.6621",
    channelUrl: "https://www.youtube.com/@hughhou",
    channelName: "@hughhou",
  },
  {
    place: "St Thomas US Virgin Islands",
    id: "67AZDQ9kBoY",
    lat: "18.3381",
    lng: "-64.8941",
    channelUrl: "https://www.youtube.com/@hughhou",
    channelName: "@hughhou",
  },
  {
    place: "Salem, MA, USA",
    id: "UG9_GlViksI",
    lat: "42.5197",
    lng: "-70.8955",
    channelUrl: "https://www.youtube.com/@hughhou",
    channelName: "@hughhou",
  },
  {
    place: "Athens",
    id: "Du0w_6y_7ao",
    lat: "37.9838",
    lng: "23.7275",
    channelUrl: "https://www.youtube.com/@hughhou",
    channelName: "@hughhou",
  },
  {
    place: "Abu Dhabi",
    id: "KkCDexWdDIY",
    lat: "24.4539",
    lng: "54.3773",
    channelUrl: "https://www.youtube.com/@hughhou",
    channelName: "@hughhou",
  },
  {
    place: "Dubai",
    id: "CUJKr_dZcb4",
    lat: "25.2048",
    lng: "55.2708",
    channelUrl: "https://www.youtube.com/@hughhou",
    channelName: "@hughhou",
  },
  {
    place: "Kauai, Hawaii",
    id: "19qpN13zHT0",
    lat: "22.0964",
    lng: "-159.5261",
    channelUrl: "https://www.youtube.com/@hughhou",
    channelName: "@hughhou",
  },
  {
    place: "Tokyo",
    id: "pxleh0ePUWQ",
    lat: "35.6762",
    lng: "139.6503",
    channelUrl: "https://www.youtube.com/@hughhou",
    channelName: "@hughhou",
  },
  {
    place: "Kauai, Hawaii",
    id: "MXjSnbgjSyM",
    lat: "22.0174",
    lng: "-159.5371",
    channelUrl: "https://www.youtube.com/@hughhou",
    channelName: "@hughhou",
  },
  {
    place: "Wahab Al Karama",
    id: "_e42SrxYtCU",
    lat: "24.41410105",
    lng: "54.4786611780089",
    channelUrl: "https://www.youtube.com/@hughhou",
    channelName: "@hughhou",
  },
  {
    place: "Hollywood",
    id: "8gIROU4gMxQ",
    lat: "34.114214",
    lng: "-118.346540",
    channelUrl: "https://www.youtube.com/@hughhou",
    channelName: "@hughhou",
  },

  {
    place: "Skiathos, Greece",
    id: "nCBWqSO8D1Y",
    lat: "39.1635",
    lng: "23.4902",
    channelUrl: "https://www.youtube.com/@philipbloom",
    channelName: "@philipbloom",
  },

  {
    place: "Hangzhou, China",
    id: "f8xO2A-7dQY",
    lat: "30.2741",
    lng: "120.1552",
    channelUrl: "https://www.youtube.com/@mytimesvr1023",
    channelName: "@mytimesvr1023",
  },

  {
    place: "Papua New Guinea",
    id: "dcu_JHaQ600",
    lat: "-6.3150",
    lng: "143.9555",
    channelUrl: "https://www.youtube.com/@trostlefilms",
    channelName: "@trostlefilms",
  },

  {
    place: "Bayete Zulu, South Africa",
    id: "Fd_XYAacIvc",
    lat: "-27.799093",
    lng: "32.085008",
    channelUrl: "https://www.youtube.com/@Vrgorilla1",
    channelName: "@Vrgorilla1",
  },

  // {
  //   place: "",
  //   id: "",
  //   lat: "",
  //   lng: "",
  //   channelUrl: "https://www.youtube.com/@Vrgorilla1",
  //   channelName: "@Vrgorilla1",
  // },
];
