import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faPlayCircle,
  faSpinner,
  faFrog,
  faVrCardboard,
} from "@fortawesome/free-solid-svg-icons";
import YouTube from "react-youtube";
import { videos } from "./youtube-videos";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

const Marker = (options: any) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      const newOptions = { ...options };
      delete newOptions["video"];
      delete newOptions["setVideo"];
      delete newOptions["setPanelOpen"];
      marker.setOptions(newOptions);
    }
  }, [marker, options]);

  if (marker) {
    marker.addListener("click", () => {
      options && options.map && options.map.setZoom(10);

      options &&
        options.map &&
        options.map.panTo({
          lat: parseFloat(options?.video?.lat),
          lng: parseFloat(options?.video?.lng),
        });

      if (options && options.video) {
        options.setPanelOpen(true);
        options.setVideo(options.video);
      }
    });
  }

  return null;
};

const Panel = ({ setPanelOpen, details, setVideo, setDetails, video }: any) => {
  const playVideo = () => {
    if (details && details.target && details.target.getIframe()) {
      details.target.getIframe().requestFullscreen();
      details.target.playVideo();

      details.target.getIframe().onfullscreenchange = (event: any) => {
        if (!document.fullscreenElement) {
          details.target.stopVideo();
        }
      };
    }
  };
  const closeVideo = () => {
    setPanelOpen(false);
    setDetails(null);
    setVideo(null);
  };

  return (
    <>
      <Background onClick={() => closeVideo()} />

      <StyledPanel>
        {details?.target?.getVideoData() ? (
          <>
            <CloseButtonContainer>
              <FontAwesomeIcon icon={faTimes} onClick={() => closeVideo()} />
            </CloseButtonContainer>
            <Title>
              {`${details?.target?.getVideoData().title} by `}
              <ChannelLink href={video.channelUrl} target="_blank">
                {video.channelName}
              </ChannelLink>
            </Title>
            <Thumbnail>
              <VideoImage
                src={`https://img.youtube.com/vi/${
                  details?.target?.getVideoData().video_id
                }/0.jpg`}
              />
            </Thumbnail>

            <PlayIcon>
              <PlayInFullscreenText onClick={() => playVideo()}>
                Play in Fullscreen
              </PlayInFullscreenText>
              <StyledPlayButton
                icon={faPlayCircle}
                onClick={() => playVideo()}
              />
            </PlayIcon>
          </>
        ) : (
          <SpinnerContainer>
            <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
          </SpinnerContainer>
        )}
      </StyledPanel>
    </>
  );
};

export function DataMap(props: any) {
  const ref = React.useRef<HTMLDivElement>(null);

  const mapStyles = {
    height: "100vh",
    width: "100%",
  };

  useEffect(() => {
    if (ref.current && !props.map) {
      props.setMap(
        new window.google.maps.Map(ref.current, {
          center: props.center,
          zoom: props.zoom,
          mapTypeControl: false,
          streetViewControl: true,
          fullscreenControl: false,
          // zoomControl: false,
        })
      );
    }
  }, [ref, props.center, props.zoom]);

  return (
    <>
      <div ref={ref} id="map" style={mapStyles} />
      {React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map: props.map });
        }
      })}
    </>
  );
}

function App() {
  const [panelOpen, setPanelOpen] = useState(false);
  const [video, setVideo] = useState<any>(null);
  const [details, setDetails] = useState<any>(null);

  const [center, setCenter] = useState({ lat: 42.35866, lng: 0 });
  const [zoom, setZoom] = useState(3);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (map) {
      map.setCenter({
        lat: parseFloat(video?.lat),
        lng: parseFloat(video?.lng),
      });

      map.setZoom(10);
    }
  }, [video]);

  const hop = () => {
    const randomVideo =
      videos[Math.floor(Math.random() * (videos.length - 1) + 0)];

    if (map) {
      map.panTo({
        lat: parseFloat(randomVideo.lat),
        lng: parseFloat(randomVideo.lng),
      });
      map.setZoom(10);
    }
    // setPanelOpen(true);
    // setVideo(randomVideo);
  };

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      {panelOpen && (
        <Panel
          setPanelOpen={setPanelOpen}
          details={details}
          setVideo={setVideo}
          setDetails={setDetails}
          video={video}
        />
      )}

      <Menu>
        <Logo href="/">
          <i>Awesome</i>
          <span style={{ color: "green" }}>Hop</span>
        </Logo>

        <SubTitle>
          VR Videos{" "}
          <FontAwesomeIcon
            style={{ fontSize: "18px", verticalAlign: "bottom" }}
            icon={faVrCardboard}
          />
        </SubTitle>

        <HopButton onClick={() => hop()}>
          Hop <FontAwesomeIcon icon={faFrog} />
        </HopButton>

        <ContactForm
          href="https://docs.google.com/forms/d/e/1FAIpQLSecRHuPPEyLkAX9sKyJ1ytir6O3htXivYOnoTRBu2XXHuJoMQ/viewform?usp=sf_link"
          target="_blank"
        >
          Contact
        </ContactForm>
      </Menu>

      <Wrapper apiKey={"AIzaSyBNOe81krRhsUfoMXQ5jIbdG-yV1kNvoZI"}>
        <DataMap center={center} zoom={zoom} setMap={setMap} map={map}>
          {videos.map((video) => (
            <Marker
              key={video.id}
              position={{
                lat: parseFloat(video.lat),
                lng: parseFloat(video.lng),
              }}
              video={video}
              setPanelOpen={setPanelOpen}
              setVideo={setVideo}
            />
          ))}
        </DataMap>
      </Wrapper>

      {video?.id && (
        <YouTube
          videoId={video.id}
          opts={{
            height: "0",
            width: "0",
          }}
          onReady={(event) => {
            setDetails(event);
          }}
        />
      )}
    </div>
  );
}

const StyledPanel = styled.div`
  background-color: white;
  padding: 1%;
  background-repeat: no-repeat;
  border-bottom: 1px solid rgb(238, 238, 238);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 2px;
  width: 62%;
  z-index: 10;
  position: absolute;
  margin: 4% 18%;
`;

const CloseButtonContainer = styled.a`
  text-decoration: none;
  color: black;
  font-size: 16px;
  text-align: right;
  display: block;

  svg:hover {
    cursor: pointer;
  }
`;

const Title = styled.h4`
  margin-top: 0;
  text-align: center;
`;

const ChannelLink = styled.a`
  color: blue;
  text-decoration: none;
`;

const Thumbnail = styled.div`
  text-align: center;
`;

const Background = styled.div`
  background-color: #000;
  opacity: 0.5;
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 9;
`;

const PlayIcon = styled.div`
  text-align: center;
  font-size: 40px;
`;

const StyledPlayButton = styled(FontAwesomeIcon)`
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
`;

const PlayInFullscreenText = styled.span`
  font-size: 16px;
  line-height: 40px;
  vertical-align: middle;
  margin-right: 5px;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;

const VideoImage = styled.img`
  max-height: 200px;
`;

const SpinnerContainer = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 26px;
`;

const Menu = styled.div`
  background-color: white;
  padding: 1%;
  background-repeat: no-repeat;
  border-bottom: 1px solid rgb(238, 238, 238);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 2px;
  width: 200px;
  z-index: 5;
  position: absolute;
  top: 20px;
  left: 20px;
  text-align: center;
`;

const Logo = styled.a`
  color: black;
  display: block;
  font-size: 26px;
  text-decoration: none;
`;

const SubTitle = styled.p`
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 2px;
  line-height: 18px;
`;

const HopButton = styled.button`
  margin-bottom: 20px;
  color: white;
  background: green;
  border: 0;
  width: 100%;
  padding: 10px;
  font-size: 24px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  &:hover {
    cursor: pointer;
    background: darkgreen;
  }
`;

const ContactForm = styled.a`
  color: black;
  font-size: 14px;
  text-decoration: none;
`;

export default App;
